<template>
  <DashboardSection :title="locale.title">
    <BalanceInfo />
  </DashboardSection>
</template>

<script setup>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import { useLocale } from 'lib/helpers/useLocale'
import BalanceInfo from 'Partner/pages/PartnerBalance/components/BalanceInfo'
import { computed, onMounted } from 'vue'

import { vm } from '@/main'

const locale = computed(() => ({
  title: useLocale('info.title'),
}))

onMounted(() => {
  vm.$store.dispatch('partner/fetchWallet')
})
</script>

<template>
  <div class="wallet-balance">
    <div class="headline-2 wallet-balance__sum">
      {{ balance | toCurrency }}
    </div>
    <div class="text-m-regular wallet-balance__link">
      <a href="mailto:pavel@uplify.app">{{ locale.howFundUpBalance }}</a>
    </div>
  </div>
</template>

<script setup>
import { useLocale } from 'lib/helpers/useLocale'
import { computed } from 'vue'

import { vm } from '@/main'

const locale = computed(() => ({
  howFundUpBalance: useLocale('info.howFundUpBalance'),
}))

const wallet = computed(() => vm.$store.getters['partner/wallet'])
const balance = computed(() => wallet.value?.balance || 0.0)
</script>

<style lang="scss" scoped>
.wallet-balance {
  background-color: $--color-primary-light-8;
  border-radius: $--border-radius-large;
  padding: 20px 8px;
  text-align: center;

  &__sum {
    margin-bottom: 6px;
  }
}
</style>

<template>
  <DashboardSection :title="locale.title">
    <BalanceHistoryTable />

    <ElPagination
      v-if="transactions.bootstrapped"
      layout="prev, pager, next"
      :prev-text="locale.prev"
      :next-text="locale.next"
      :page-size="transactions.perPage"
      :current-page="transactions.page"
      :total="transactions.total"
      hide-on-single-page
      @current-change="changePage"
    />
  </DashboardSection>
</template>

<script setup>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElPagination from 'element/ElPagination'
import { useLocale, useRootLocale } from 'lib/helpers/useLocale'
import BalanceHistoryTable from 'Partner/pages/PartnerBalance/components/BalanceHistoryTable'
import { computed, onMounted } from 'vue'

import { vm } from '@/main'

const locale = computed(() => ({
  title: useLocale('history.title'),
  next: useRootLocale('pagination.next'),
  prev: useRootLocale('pagination.prev'),
}))

const transactions = computed(() => vm.$store.getters['partner/transactions'])

onMounted(() => {
  vm.$store.dispatch('partner/fetchTransactions')
})

const changePage = (page) => {
  vm.$store.dispatch('partner/setTransactionsPage', page)
}
</script>

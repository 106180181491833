<template>
  <div
    v-loading="transactions.loading"
    class="wallet-history-table"
  >
    <ElTable
      v-if="hasTransactions"
      :data="transactions.items"
      style="width: 100%"
    >
      <ElTableColumn
        :label="locale.date"
        prop="date"
      />
      <ElTableColumn
        :label="locale.amount"
      >
        <template slot-scope="{ row }">
          {{ row.amount | toCurrency }}
        </template>
      </ElTableColumn>
      <ElTableColumn
        :label="locale.service"
        prop="service"
      />
    </ElTable>

    <div
      v-else
      class="wallet-history-table__none"
    >
      {{ locale.noPayments }}
    </div>
  </div>
</template>

<script setup>
import ElTable from 'element/ElTable'
import ElTableColumn from 'element/ElTableColumn'
import loading from 'element/Loading'
import { useLocale } from 'lib/helpers/useLocale'
import { computed } from 'vue'

import { vm } from '@/main'

const vLoading = loading

const locale = computed(() => {
  return {
    noPayments: useLocale('history.noPayments'),
    date: useLocale('history.table.columns.date'),
    amount: useLocale('history.table.columns.amount'),
    service: useLocale('history.table.columns.service'),
  }
})
const transactions = computed(() => vm.$store.getters['partner/transactions'])
const hasTransactions = computed(() => transactions?.value?.bootstrapped && transactions?.value.items?.length)
</script>

<style lang="scss" scoped>
.wallet-history-table {
  &__none {
    text-align: center;
    color: $--color-dark-gray;
  }
}
</style>

<template>
  <DashboardLayout :full-width="true">
    <DashboardTitle :title="locale.title" />
    <BalanceInfoSection />
    <BalanceHistorySection />
  </DashboardLayout>
</template>

<script setup>
import DashboardTitle from 'components/layouts/DashboardLayout/components/DashboardTitle'
import DashboardLayout from 'components/layouts/DashboardLayout/index.vue'
import { useLocale } from 'lib/helpers/useLocale'
import BalanceHistorySection from 'Partner/pages/PartnerBalance/sections/BalanceHistorySection'
import BalanceInfoSection from 'Partner/pages/PartnerBalance/sections/BalanceInfoSection'
import { computed } from 'vue'

const locale = computed(() => ({
  title: useLocale('header.title'),
}))
</script>
